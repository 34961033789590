.copy-exam-button-wrapper {
  background-color: #efefef;
  border-radius: 30px;
  color: black;
  border: none;
  outline: none !important;
  font-weight: bold;
  &:hover {
    background-color: #efefefb2;
  }
  &:active {
    background-color: #efefefa9;
  }
}
.add-subscription-button-wrapper {
  background-color: #e27754;
  border-radius: 30px;
  color: white;
  border: none;
  outline: none !important;
  font-weight: bold;
  &:hover {
    background-color: rgba(226, 120, 84, 0.877);
  }
  &:active {
    background-color: rgba(226, 120, 84, 0.795);
  }
}


.subscription-editor-wrapper {
  .go-back-button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    &:hover {
      i {
        color: rgba(0, 0, 0, 0.781);
      }
    }
    &:hover {
      i {
        color: rgba(0, 0, 0, 0.555);
      }
    }
  }
  .top-part-wrapper {
    position: sticky;
    top: 0;
    left: 250px;
    right: 0;
    z-index: 10;
    background-color: white;
    .header-wrapper {
      .title-edit-input {
        font-size: 1.275rem;
        border: 0;
        color: #2c304e;
        border: 1px solid transparent;
        font-weight: bold;
        &:hover {
          border-color: $primary;
        }
      }
      .custom-badge {
        background-color: #f2f2f2;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .duplicate-button-wrapper {
        background-color: #efefef;
        border-radius: 20px;
        outline: none;
        border: none;
        color: #656464;
        font-weight: bold;
        font-size: 13px;
        &:hover {
          background-color: rgba(239, 239, 239, 0.781);
        }
        &:active {
          background-color: rgba(239, 239, 239, 0.61);
        }
      }
      .save-button-wrapper {
        background-color: #4193f2;
        color: #f5f5f5;
        font-weight: bold;
        font-size: 13px;
        border-radius: 10px;
        outline: none;
        border: none;
        cursor: default;
        opacity: 0.2;
        &:not([disabled]) {
          cursor: pointer;
          opacity: 1;
        }
        &:not([disabled]):hover {
          background-color: rgba(65, 148, 242, 0.856);
        }
        &:not([disabled]):hover {
          background-color: rgba(65, 148, 242, 0.692);
        }
      }
      .delete-button-wrapper {
        background-color: transparent;
        outline: none;
        border: none;
        i {
          font-size: 18px;
          color: #a1a1a1;
        }
        &:hover {
          i {
            color: rgba(161, 161, 161, 0.822);
          }
        }
        &:active {
          i {
            color: rgba(161, 161, 161, 0.637);
          }
        }
      }
    }
    .tab-switcher {
      background-color: #efefef;
      border-top: 1px solid #70707034;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      a,
      button {
        background-color: transparent;
        outline: none;
        border: none;
        color: #bebdbd;
        font-weight: bold;
        text-decoration: none;
      }
      .selected {
        color: #e27754;
        border-bottom: 4px solid #e27754;
      }
    }
  }
}
.sticky-button-wrapper {
  position: sticky;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 10;
  background-color: white;
}
