.note-component-wrapper {
  display: flex;
  justify-content: space-between;
  border: 1px solid #70707060;
  border-radius: 5px;
  .pdf-button-wrapper {
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 12px;
    line-height: 23px;
    i {
      color: #cc2b2b;
    }
  }
  .remove-file-button-wrapper {
    background-color: transparent;
    border: none;
    outline: none;
    color: #858585;
    font-weight: bold;
    text-decoration: underline;
    &:hover {
      color: #858585e1;
    }
    &:active {
      color: #858585bb;
    }
  }
}
.cancel-button-wrapper {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: bold;
  text-decoration: underline;
  i {
    color: black;
  }
  &:hover {
    outline: none !important;
    i {
      color: rgba(0, 0, 0, 0.671);
    }
  }
  &:active {
    outline: none !important;
    i {
      color: rgba(0, 0, 0, 0.541);
    }
  }
}
