.mark-wrapper {
  display: flex;
  flex-direction: row;
  label {
    color: #b5b5b5;
    font-weight: bold;
  }
  input {
    max-width: 70px;
  }
}
hr {
  border-color: #ebebeb;
}

.exam-creater-wrapper {
  background-color: #f9f9f9;
  .exam-details {
    position: sticky;
    top: 147px;
    left: 0px;
    right: 0px;
    background: #f9f9f9;
    z-index: 100;
    textarea {
      max-width: 800px;
    }
    p {
      color: #b5b5b5;
    }
    .subject-button-group {
      button {
        background-color: #efefef;
        color: #242424;
        border: none;
        outline: none !important;
        &:hover {
          background-color: rgba(239, 239, 239, 0.63);
          outline: none !important;
        }
        &:active {
          background-color: rgba(239, 239, 239, 0.432) !important;
          outline: none !important;
          color: #242424 !important;
          border: none !important;
        }
      }
      .selected {
        background-color: #6d6d6d;
        color: #ffffff;
        &:hover {
          background-color: #6d6d6d !important;
          color: #ffffff !important;
        }
        &:active {
          background-color: #6d6d6d !important;
          color: #ffffff !important;
        }
      }
    }
    .import-export-dropdown-button {
      background-color: #ffffff;
      color: #6b6969;
      font-weight: bold;
    }
  }
  .title-wrapper {
    label {
      color: #a5a5a5;
    }
  }
}
.Question-creator-wrapper {
  background-color: #f2f2f2;
  .monetization-button {
    background-color: transparent;
    border: none;
    outline: none !important;
    color: #c8cbc7;
  }
  .selected {
    background-color: #daf1d1;
    color: #55d950;
  }
  .question-edit-input {
    font-size: 1.275rem;
    border: 0;
    color: #242424;
    border: 1px solid transparent;
    font-size: 13px;
    width: 100% !important;
    background-color: #f2f2f2;
    &:hover {
      border-color: $primary;
    }
  }
  input {
    max-width: 250px;
  }
  .search-wrapper {
    position: relative;
    input {
      font-size: 13px;
    }
    i {
      position: absolute;
      right: 25px;
      top: 12px;
      color: #949494;
      font-size: 12px;
    }
  }
}
// .exam-catalogue-wrapper {
//   background-color: #f9f9f9;
// }
.exam-monetization-wrapper {
  .description {
    font-size: 12px;
    color: #8f8f8f;
  }
}
