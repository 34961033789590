.auth-view-wrapper {
  .background-image-wrapper {
    height: 100vh;
    background-image: url(../images/theme.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media-breakpoint-down(md) {
      height: 100vh;
    }
    h2 {
      color: #2b2b2b;
    }
    h1 {
      font-weight: bold;
      color: #2b2b2b;
    }
    label {
      font-weight: bold;
    }
    input {
      font-size: 12px;
    }
    .login-button-wrapper {
      background-color: #e27754;
      border-radius: 5px;
      color: white;
      border: none;
      outline: none;
      font-weight: bold;
      font-size: 15px;
      &:hover {
        background-color: rgba(226, 120, 84, 0.877);
      }
      &:hover {
        background-color: rgba(226, 120, 84, 0.795);
      }
    }
  }
}
