.comment-item {
  &:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
  &:nth-child(odd) {
    background-color: #f5f5f5;
    .seperator {
      height: 100px;
      background-color: #d9d6d6;
      width: 5px;
    }
  }
  .comment-reply-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .seperator {
    height: 100px;
    background-color: #f5f5f5;
    width: 5px;
  }
  .reply-wrapper {
    &:hover {
      .reply-actions-wrapper {
        opacity: 1;
      }
    }
  }
  .reply-actions-wrapper {
    opacity: 0;
  }
  .user-name-wrapper {
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    height: 20px;
  }
  // .replies-wrapper{
  //   max-height: 150px;
  //   overflow: auto;

  // }
}
.dicsuiion-wrapper {
  .discussion-layout-wrapper {
    flex-direction: row;
    .discussion-sidebar {
      position: fixed;
      left: 250;
      top: 0;
      bottom: 0;
      background-color: #fbf1e5;
      width: 320px;
      h5 {
        color: #242424;
        font-weight: bold;
      }
      label {
        color: #989898;
        font-size: 13px;
      }
      .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        .nav-link {
          display: block;
          color: #242424;
          font-weight: bold;
          font-size: 16;
        }
        & .selected {
          background-color: #ffe5c8;
        }
      }
    }
  }
}
.go-back-button {
  background-color: transparent;
  border: none;
  outline: none;
  i {
    color: #242424;
  }
  &:hover {
    i {
      color: rgba(0, 0, 0, 0.781);
    }
  }
  &:hover {
    i {
      color: rgba(0, 0, 0, 0.555);
    }
  }
}

.add-button-wrapper {
  border-radius: 30px;
  border-color: #e27754;
  font-weight: bold;
  outline: none;
  &:hover {
    background-color: transparent !important;
    outline: none;
    color: #e27754 !important;
  }
  &:active {
    background-color: transparent !important;
    outline: none;
    color: #e27754 !important;
  }
}

.custom-button-group {
  button {
    background-color: #efefef;
    color: #242424;
    border: none;
    outline: none !important;
    &:hover {
      background-color: rgba(239, 239, 239, 0.63);
      outline: none !important;
    }
    &:active {
      background-color: rgba(239, 239, 239, 0.432) !important;
      outline: none !important;
      color: #242424 !important;
      border: none !important;
    }
  }
  .selected {
    background-color: #ef5a29;
    color: #ffffff;
    &:hover {
      background-color: #ef5a29 !important;
      color: #ffffff !important;
    }
    &:active {
      background-color: #ef5a29 !important;
      color: #ffffff !important;
    }
  }
}

.discussion-emty-page-content {
  margin-left: 320px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .empty-subject-wrapper {
    i {
      color: #dcdcdc;
      font-size: 50px;
    }
    h5 {
      color: #cbcbcb;
      font-weight: normal;
      font-size: 15px;
    }
  }
}

.discussion-editor-content {
  margin-left: 320px;
  .header-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h4 {
      font-weight: normal;
      color: #242424;
    }
  }
  .group-button-and-sort-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .sort-dropdown-button {
      background-color: #ffffff;
      color: #6b6969;
      font-size: 14px;
    }
  }
  .table-responsive {
    .table {
      th {
        border: none;
        color: #a5a5a5;
        font-weight: normal;
      }
      tr {
        color: #242424;
        td {
          border-top: white solid 4px !important;
          background-color: #f2f2f2;
          .comment-input {
            font-size: 1.275rem;
            border: 0;
            color: #242424;
            border: 1px solid transparent;
            font-size: 13px;
            width: 100% !important;
            background-color: #f2f2f2;
            &:hover {
              border-color: $primary;
            }
          }
          .status-button-wrapper {
            background-color: #e6e6e6;
            border-radius: 20px;
            border: none;
            outline: none !important;
            color: #6b6969;
            font-weight: bold;
          }
        }
      }
    }
  }
}
