.video-catalogue-wrapper {
  .go-back-button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    &:hover {
      i {
        color: rgba(0, 0, 0, 0.781);
      }
    }
    &:hover {
      i {
        color: rgba(0, 0, 0, 0.555);
      }
    }
  }
  .top-part-wrapper {
    position: sticky;
    top: 0;
    left: 250px;
    right: 0;
    z-index: 10;
    background-color: white;
    .tab-switcher {
      background-color: #efefef;
      border-top: 1px solid #70707034;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background-color: transparent;
        outline: none;
        border: none;
        color: #bebdbd;
        font-weight: bold;
      }
      .selected {
        color: #e27754;
        border-bottom: 4px solid #e27754;
      }
    }
  }
}

.add-lecture-button-wrapper {
  background-color: #e27754;
  border-radius: 30px;
  color: white;
  border: none;
  outline: none !important;
  font-weight: bold;
  &:hover {
    background-color: rgba(226, 120, 84, 0.877);
  }
  &:active {
    background-color: rgba(226, 120, 84, 0.795);
  }
}
.delete-button-wrapper {
  background-color: transparent;
  outline: none;
  border: none;
  i {
    font-size: 18px;
    color: #a1a1a1;
  }
  &:hover {
    i {
      color: rgba(161, 161, 161, 0.822);
    }
  }
  &:active {
    i {
      color: rgba(161, 161, 161, 0.637);
    }
  }
}

.status-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: #46bc71;
    font-size: 10px;
  }
  label {
    color: #acacac;
  }
}

.sticky-button-wrapper {
  position: sticky;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 10;
  background-color: white;
}
.title-edit-input {
  font-size: 1.275rem;
  border: 0;
  color: #2c304e;
  border: 1px solid transparent;
  font-weight: bold;
  &:hover {
    border-color: $primary;
  }
}
