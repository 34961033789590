.course-editor {
  input[type="file"] {
    display: none;
  }

  .tab-switcher {
    background-color: #efefef;
    border-top: 1px solid #70707034;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 77px;
    z-index: 1;
    a,
    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: #bebdbd;
      font-weight: bold;
      text-decoration: none;
    }
    .selected {
      color: #e27754;
      border-bottom: 4px solid #e27754;
    }
  }

  .go-back-button {
    background-color: transparent;
    border: none;
    outline: none;
    &:hover {
      i {
        color: rgba(0, 0, 0, 0.781);
      }
    }
    &:hover {
      i {
        color: rgba(0, 0, 0, 0.555);
      }
    }
  }
  .edit-button-wrapper {
    background-color: transparent;
    color: #b1b1b1;
    font-weight: bold;
    border: none;
    outline: none;
    text-decoration: underline;
    font-size: 15px;
    &:hover {
      color: rgba(177, 177, 177, 0.767);
    }
    &:hover {
      color: rgba(177, 177, 177, 0.548);
    }
  }
  .add-button-wrapper {
    border-radius: 30px;
    border-color: #e27754;
    font-weight: bold;
    outline: none;
    &:hover {
      background-color: transparent !important;
      outline: none;
      color: #e27754 !important;
    }
    &:active {
      background-color: transparent !important;
      outline: none;
      color: #e27754 !important;
    }
  }
  .header-wrapper {
    border-bottom: #70707057 1px solid;
    z-index: 100;
    background-color: white;
    position: sticky;
    top: 0;
    .go-back-button {
      background-color: transparent;
      border: none;
      outline: none;
      &:hover {
        i {
          color: rgba(0, 0, 0, 0.781);
        }
      }
      &:hover {
        i {
          color: rgba(0, 0, 0, 0.555);
        }
      }
    }
    h4 {
      color: #2c304e;
    }
    .title-edit-input {
      font-size: 1.275rem;
      border: 0;
      color: #2c304e;
      border: 1px solid transparent;
      font-weight: bold;
      &:hover {
        border-color: $primary;
      }
    }
    .search-wrapper {
      position: relative;
      input {
        max-width: 400px;
        background-color: #f3f3f3;
        border: none;
      }
      i {
        color: #949494;
        position: absolute;
        top: 17px;
        font-size: 12px;
      }
    }
    .save-button-wrapper {
      background-color: #3b79d8;
      border-radius: 30px;
      border: none;
      font-weight: bold;
      font-size: 15px;
      cursor: default;
      opacity: 0.2;
      &:not([disabled]) {
        cursor: pointer;
        opacity: 1;
      }
      &:not([disabled]):hover {
        background-color: rgba(112, 169, 255, 0.829);
      }
    }
  }
  // .side-nav-bar {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   bottom: 0;
  //   background-color: #f6f6f6;
  //   width: 300px;
  //   margin-top: 85px;
  //   .nav-link {
  //     text-decoration: none;
  //     background-color: transparent;
  //     color: #aeaeae;
  //     border: none;
  //     text-align: left;
  //     font-weight: bold;
  //     outline: none;
  //   }
  //   .selected {
  //     background-color: #ffe5c8;
  //     color: #242424;
  //     border-right: 8px #ef5a29 solid;
  //   }
  // }
  .course-editor-content-wrapper {
    // margin-left: 320px;
    // margin-top: 100px;
    .sublect-page-wrapper {
      .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .subject-title-wrapper {
          display: flex;
          flex-direction: row;
          h3 {
            color: #242424;
            font-weight: bold;
          }
        }
        .add-subject-button-wrapper {
          border-radius: 30px;
          border-color: #e27754;
          color: #e27754;
          font-weight: bold;
          outline: none;
          &:hover {
            background-color: transparent;
            outline: none;
          }
          &:active {
            background-color: transparent;
            outline: none;
          }
        }
      }
      .subjects-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .topic-page-wrapper {
      .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .subject-title-wrapper {
          display: flex;
          flex-direction: row;
          h3 {
            font-weight: lighter;
          }
          input.subject-title-edit-input {
            margin-left: 2px;
            font-size: 1.0625rem;
            border: none;
            color: #212529;
            padding-left: 4px;
            border: 1px solid transparent;
            &:hover {
              border-color: $primary;
            }
          }
        }
      }
      img {
        width: 300px;
        border-radius: 10px;
      }
      .lectures-wrapper {
        display: flex;
        flex-direction: column;
        h4 {
          color: #a7a7a7;
          font-weight: bold;
        }
      }
    }
    .lecture-details-page-wrapper {
      .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .subject-title-wrapper {
          display: flex;
          flex-direction: row;
          h3 {
            font-weight: lighter;
          }
        }
        .add-course-button-wrapper {
          background-color: #e27754;
          border-radius: 30px;
          color: white;
          border: none;
          outline: none;
          font-weight: bold;
          &:hover {
            background-color: rgba(226, 120, 84, 0.877);
          }
          &:hover {
            background-color: rgba(226, 120, 84, 0.795);
          }
        }
        .cancel-button-wrapper {
          background-color: transparent;
          border: none;
          outline: none;
          font-weight: bold;
          text-decoration: underline;
          &:hover {
            color: rgba(0, 0, 0, 0.863);
          }
          &:hover {
            color: rgba(0, 0, 0, 0.753);
          }
        }
        .save-button-wrapper {
          background-color: #f1f1f1;
          border: none;
          outline: none;
          font-weight: bold;
          border-radius: 30px;
          &:hover {
            background-color: rgba(241, 241, 241, 0.808);
          }
          &:hover {
            background-color: rgba(241, 241, 241, 0.719);
          }
        }
      }
      .current-lecture-wrapper {
        input {
          font-size: 15px;
          border-color: #7070705e;
        }
        .video-wrapper {
          height: 300px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .video-lecture-details {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          label {
            font-size: 12px;
            color: #242424;
          }
          span {
            color: #afafaf;
            font-size: 13px;
          }
          .remove-video-button-wrapper {
            background-color: transparent;
            color: #e27754;
            font-weight: bold;
            border: none;
            outline: none;
            text-decoration: underline;
            &:hover {
              color: rgba(226, 120, 84, 0.815);
            }
            &:active {
              color: rgba(226, 120, 84, 0.733);
            }
          }
        }
      }
    }
    .add-new-lecture-wrapper {
      .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .subject-title-wrapper {
          display: flex;
          flex-direction: row;
          h3 {
            font-weight: lighter;
          }
        }
        .add-course-button-wrapper {
          background-color: #e27754;
          border-radius: 30px;
          color: white;
          border: none;
          outline: none;
          font-weight: bold;
          &:hover {
            background-color: rgba(226, 120, 84, 0.877);
          }
          &:active {
            background-color: rgba(226, 120, 84, 0.795);
          }
        }
        .cancel-button-wrapper {
          background-color: transparent;
          border: none;
          outline: none;
          font-weight: bold;
          text-decoration: underline;
          &:hover {
            color: rgba(0, 0, 0, 0.863);
          }
          &:hover {
            color: rgba(0, 0, 0, 0.753);
          }
        }
        .save-button-wrapper {
          background-color: #f1f1f1;
          border: none;
          outline: none;
          font-weight: bold;
          border-radius: 30px;
          &:hover {
            background-color: rgba(241, 241, 241, 0.808);
          }
          &:hover {
            background-color: rgba(241, 241, 241, 0.719);
          }
        }
      }
      .current-lecture-wrapper {
        input {
          font-size: 15px;
          border-color: #7070705e;
        }
        .video-wrapper {
          background-image: url(../images/youtube.png);
          height: 300px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
}
