.exam-listings-wrapper {
  .custom-button-group {
    button {
      background-color: #efefef;
      color: #242424;
      border: none;
      outline: none !important;
      &:hover {
        background-color: rgba(239, 239, 239, 0.63);
        outline: none !important;
      }
      &:active {
        background-color: rgba(239, 239, 239, 0.432) !important;
        outline: none !important;
        color: #242424 !important;
        border: none !important;
      }
    }
    .selected {
      background-color: #ef5a29;
      color: #ffffff;
      &:hover {
        background-color: #ef5a29 !important;
        color: #ffffff !important;
      }
      &:active {
        background-color: #ef5a29 !important;
        color: #ffffff !important;
      }
    }
  }
}
.add-exam-modal-wrapper {
  border-radius: 10px;
  .modal-header {
    border: none;
  }
  h5 {
    color: #242424;
  }
  label {
    color: #8e8e8e;
  }
  .modal-content {
    border: none;
  }
  .custom-modal-footer {
    background-color: #f6f6f6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    label {
      color: #242424;
      font-weight: bold;
    }
    .input-and-submit-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      input {
        font-size: 13px;
      }
      .create-exam-wrapper {
        border-radius: 20px;
      }
    }
  }
}

.exam-template-component-wrapper {
  border: solid 1px #dbdbdb;
  outline: none !important;
  background-color: white;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  label {
    font-size: 12px;
    color: #989898;
  }
  .exam-details-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .content-wrapper {
      width: 60px;
      background-color: #efefef;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      h6 {
        color: #4a4a4a;
      }
      i {
        color: #d5d3d1;
      }
      label {
        color: #d5d3d1;
        font-size: 10px;
      }
    }
  }
}
.selected-template {
  background-color: #fff7ef;
  border: solid 1px #e18566;
  .exam-details-wrapper {
    .content-wrapper {
      background-color: #ffebd5;
      i {
        color: #cfa16b;
      }
      label {
        color: #cfa16b;
      }
    }
  }
}
.add-exam-button-wrapper {
  background-color: #e27754;
  border-radius: 30px;
  color: white;
  border: none;
  outline: none !important;
  font-weight: bold;
  &:hover {
    background-color: rgba(226, 120, 84, 0.877);
    border: none;
    outline: none;
  }
  &:active {
    background-color: rgba(226, 120, 84, 0.795);
    border: none;
    outline: none;
  }
}

.sticky-button-wrapper {
  position: sticky;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 10;
  background-color: white;
}
