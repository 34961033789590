.courses-wrapper {
  .side-navbar-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgb(242, 213, 175);
    background: linear-gradient(
      180deg,
      rgba(242, 213, 175, 1) 0%,
      rgba(222, 204, 204, 1) 100%
    );
    width: 250px;
    .profile-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      i {
        font-size: 15px;
        background-color: #ffe5c8;
        border-radius: 50%;
      }
      h6 {
        font-weight: bold;
        color: #242424;
      }
      .logout-button-wrapper {
        text-decoration: none;
      }
    }
    .nav-bar-wrapper {
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        li {
          a {
            display: block;
            text-decoration: none;
            color: #ac957c;
            font-weight: bold;
          }
        }
        .selected {
          background-color: #ffe5c8;
          border-right: 8px #ef5a29 solid;
          a {
            color: #242424;
          }
        }
        .link {
          display: flex;
          flex-wrap: wrap;
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;
          font-weight: bold;
          color: #ac957c;
          padding-left: 13.6px;
          text-decoration: none;
          padding-top: 13.6px;
          padding-bottom: 13.6px;
        }
      }
    }
  }
}

.course-listing-wrapper {
  .add-course-button-wrapper {
    background-color: #e27754;
    border-radius: 30px;
    color: white;
    border: none;
    outline: none;
    font-weight: bold;
    &:hover {
      background-color: rgba(226, 120, 84, 0.877);
    }
    &:hover {
      background-color: rgba(226, 120, 84, 0.795);
    }
  }
}
.sticky-button-wrapper {
  position: sticky;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 10;
  background-color: white;
}
