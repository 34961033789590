$spacer: 0.85rem;
$font-size-base: 0.85rem;

$primary: #e27754;
$secondary: #efefef;

#root {
  height: 100vh;
  overflow: auto;
}

@import "./../../../../node_modules/bootstrap/scss/bootstrap.scss";

@import "./../../../../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../../modules/CourseEditorModule/assets/styles/index.scss";
@import "../../modules/CourseModule/assets/styles/index.scss";
@import "../../modules/AuthModule/assets/styles/index.scss";
@import "../../modules/ExamModule/assets/styles/index.scss";
@import "../../modules/ExamEditorModule/assets/styles/index.scss";
@import "../../modules/FileUploadModule/style.scss";
@import "../../modules/SubscriptionModule/assets/styles/index.scss";
@import "../../modules/VideoCatalogue/assets/styles/index.scss";
@import "../../modules/DiscussionModule/assets/styles/index.scss";
