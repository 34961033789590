.subject-component-wrapper {
  height: 170px;
  width: 250px;
  background-color: #e3e3e3;
  border: none;
  outline: none;
  text-align: left;
  -webkit-box-shadow: 0px 9px 14px -4px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 9px 14px -4px rgba(214, 214, 214, 1);
  box-shadow: 0px 9px 14px -4px rgba(214, 214, 214, 1);
  .overlay {
    background-color: #ffffff;
    height: 100%;
    border-bottom-right-radius: 10% 30%;
    i {
      color: #dfdfdf;
    }
    h6 {
      color: #242424;
    }
    label {
      color: #c5c5c5;
      font-size: 13px;
      cursor: pointer;
    }
  }
}
.delete-button-wrapper {
  button {
    color: red;
    text-decoration: none;
  }

  .dropdown-menu {
    background-color: #e8e8e8;
    &:hover {
      background-color: rgb(233, 21, 21) !important;
    }

    &:active {
      background-color: rgb(218, 16, 16) !important;
    }
    .dropdown-item {
      color: black;

      &:hover {
        background-color: rgb(233, 21, 21) !important;
      }

      &:active {
        background-color: rgb(218, 16, 16) !important;
      }
    }
  }
}
.lecture-component-wrapper {
  background-color: transparent;
  text-align: left;
  border: none;
  outline: none;
  span {
    font-weight: bold;
  }
  .lecture-content-wraper {
    h6 {
      color: #242424;
    }
    i,
    label {
      color: #a5a5a5;
      font-size: 13px;
      cursor: pointer;
    }
  }
}
.video-gallery-wrapper,
.note-gallery-wrapper {
  background-color: transparent;
  border: none;
  outline: none;
  color: #e27754;
  border: 1px #e27754 dashed;
  border-radius: 5px;
  i {
    font-size: 25px;
  }
  h6 {
    font-weight: bold;
  }
  span {
    font-size: 12px;
  }
}

.search-wrapper {
  position: relative;
  input {
    font-size: 13px;
  }
  i {
    position: absolute;
    right: 25px;
    top: 18px;
    color: #949494;
    font-size: 12px;
  }
}

.sticky-header-wrapper {
  position: sticky;
  top: 0;
  left: 250px;
  right: 0;
  z-index: 10;
  background-color: white;
}
.move-button-wrapper {
  border: none;
  outline: none;
  background-color: transparent;
  color: #c5c5c5;
  font-size: 15px;
}

.rotate90 {
  transform: rotate(45deg);
  font-size: 15px;
  color: black;
}
