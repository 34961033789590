@import "./../../../../node_modules/react-table/react-table.css";

.ReactTable {
  border: none;
  .rt-table {
    .rt-thead {
      .rt-resizable-header-content {
        padding: 10px;
        font-weight: bold;
        font-size: 14px;
      }
      box-shadow: none;

      .rt-td {
        box-shadow: none !important;
      }

      .rt-tr {
        .rt-th {
          color: #141414;
          font-weight: 500;
          font-size: 15px;
          background-color: #f9f9f9;
          text-align: left;
        }
      }
    }
    .rt-tbody {
      .rt-tr-group {
        padding: 15px;
        color: #141414;
        font-size: 13px;
      }
    }
  }
  .-pagination {
    padding: 15px;
    background-color: #f3f3f3;
    box-shadow: none;
    border-top: none;

    .-pageInfo {
      color: #6d6666;
      font-weight: bold;
    }
  }
}
